<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="orderPage" :fields="fields" :search-model="searchModel"
                show-serial-no :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="模板名称" prop="templateName" >
          <el-input size="small" v-model="searchModel.templateName" placeholder="模板名称"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions"></template>
    </page-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageTable from "@/components/PageTable";
import  {statusMapper} from "@/utils/helper";
import {CONTRACT_MANAGEMENT_STATUS, CONTRACT_TYPE,} from "@/utils/config";
import {
  editTemplate,
  eTemplatePage, startStop,
} from "@/api/apis";

export default {
  name: "TemplateList",
  components: { PageTable, PageHeader},
  data() {
    return {
      // -
      searchModel: {
        templateName: '',
      },
      orderPage: eTemplatePage,
      fields: [
        { label: '模板名称', key: 'templateName' },
        {
          label: '创建人',  width: 200,
          render({createUser}, h) {
            return h('div', createUser ? createUser.name : '')
          }
        },
        {
          label: '所属组织',
          render({developers}, h) {
            return h('div', developers?.name)
          }
        },
        {
          label: '状态', width: 200,
          render: (item, h) => {
            return h("el-switch", {
              props: { value: String(item.state) === '0' },
              attrs: { activeColor: "#13ce66" },
              on: {
                change: () => {
                  this.changeState(item);
                },
              },
            });
          },
        },
        { label: '创建时间', key: 'time' },
      ],
      actions: [
        {
          action: 'edit',
          label: '编辑',
          type: 'primary',
          showAction: info => {
            // 线上签约 and 非撤回状态
            return info.signType != 1 && info.state != 'CANCEL'
          }
        },
      ],
      stateMap: CONTRACT_MANAGEMENT_STATUS,
      CONTRACT_TYPE,
    }
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    statusMapper,
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    // --------------------------
    /**
     * 编辑模板
     * @param item
     */
    editAction(item){
      editTemplate(item.id).then(res => {
        window.open(res, '_blank')
      })
    },
    /**
     * 更新状态
     * @param item
     */
    changeState(item){
      startStop(item.id, String(item.state) === '0' ? 1 : 0).then(() => {
        this.$refs.table.loadData()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.common-file-list-container{
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  .common-file-list-item-left{
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    //width: 608px;
    background: #F5F7FA;
    border-radius: 2px;
    font-size: 16px;
  }
  .common-file-list-item-right{
    margin-left: 14px;
    font-size: 16px;
    color: #8D9094;
  }
}
</style>